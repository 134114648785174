<script>
import { ArrowUpIcon } from 'vue-feather-icons';
import Navbar from "@/components/navbar";
import Pricing from "@/components/pricing";
import { VueRecaptcha } from 'vue-recaptcha';

/**
 * Shop checkouts component
 */
export default {
    data() {
        return {
          isInLoading: {
            submit: false,
            price: false,
          },
          plans: [],
          form: {
            fullName: '',
            email: '',
            companyName: '',
          },
          max_quantity: process.env.VUE_APP_MAX_QUANTITY,
          plan: {
            name: '',
            price: 0,
            priceForOthers: null,
            quantity: 1,
            id: '',
          },
          recaptcha: {
            apiKey: process.env.VUE_APP_RECAPTCHA_KEY,
            success: false,
            token: '',
          },
        }
    },
    components: {
        Navbar,
        ArrowUpIcon,
        VueRecaptcha,
        Pricing,
    },
    watch: {
      'plan.quantity': function () {
        if (!Number.isInteger(+this.plan.quantity)
            || this.plan.quantity < 1
            || this.plan.quantity % 1 !== 0)
        {
          this.plan.quantity = 1;
        }
        else if (this.plan.quantity > this.max_quantity) {
          this.plan.quantity = this.max_quantity;
        }
      },
      $route (){
        this.plan.quantity = 0;
        this.getPrice();
      },
    },
  beforeCreate() {
      if(!this.$route.query.slug) {
        this.$router.push({ query: { slug: process.env.VUE_APP_DEFAULT_EXTENSION }})
      }
  },
  mounted() {
    this.getPrice();
    this.getPrices();

    if (this.$store.state.userData.email) {
      this.form.email = this.$store.state.userData.email;
    }
  },
  computed: {
      total() {
        let total = this.plan.price;
        if (this.plan.priceForOthers !== null) {
          total += (this.plan.quantity-1) * this.plan.priceForOthers;
        } else {
          total = this.plan.price * this.plan.quantity;
        }
        return (total).toFixed(2);
      }
    },
    methods: {
      recaptchaError() {
        this.$toast.open({
          message: `
            <b>Error: Cannot contact reCAPTCHA. Please try the following solutions:</b>
            <ul>
            <li>Disable the VPN or Proxy service</li>
            <li>Try Incognito/Private browsing mode </li>
            <li>Disable Browser Extensions</li>
            <li>Update Browser to the latest version</li>
            </ul>
            `,
          duration: 0,
          type: 'error',
        });
      },
      getPrices() {
        this.isInLoading.prices = false;
        this.$http.get('/api/subscriptions').then(response => {
          this.plans = response.data.plans
          this.isInLoading.prices = true;
        }).catch(e => {
          this.$toast.open({
            message: 'Can not load price list. ' + e,
            type: 'error',
          });
        })
      },
      getPrice() {
        this.isInLoading.price = false;
          this.$http.get('/api/subscription/active' + (this.$route.query.slug ? `/${this.$route.query.slug}` : '')).then(response => {
            this.plan.price = response.data.plan.price;
            this.plan.priceForOthers = response.data.plan.price_for_others ?? null;
            this.plan.name = response.data.plan.title;
            this.plan.id = response.data.plan.id;
            this.plan.feature = response.data.plan.feature;
            this.isInLoading.price= true;
            if (response.data.plan.fix_quantity) {
              this.plan.fixQuantity = true;
              this.plan.quantity = response.data.plan.fix_quantity;
            } else {
              this.plan.fixQuantity = false;
              this.plan.quantity = 1;
            }
          })
        .catch(e => {
          this.$toast.open({
            message: 'Can not load price. ' + e,
            type: 'error',
          });
        })

      },
      saveCaptchaToken(token) {
        this.recaptcha.success = true;
        this.recaptcha.token = token;
      },
      formSubmit() {
        if (!this.form.fullName || !this.form.email) {
          return;
        }
        this.isInLoading.submit = true;
        this.$http.post('/api/user/new', {
          user: this.form,
          plan: this.plan,
          recaptchaToken: this.recaptcha.token,
        })
        .then(response => {
          if (response.data.sub.url) {
            this.$store.commit('setUserEmail', this.form.email);
            location.href = response.data.sub.url;
          }
        })
        .catch(e => {
          if (e?.response?.data?.message){
            this.$toast.open({
              message: e.response.data.message,
              type: 'error',
            });
          } else {
            console.error(e);
          }
        })
        .finally(() => {
          this.$refs.recaptcha.reset();
          this.isInLoading.submit = false;
          this.recaptcha.success = false;
        })
      }
    }
}
</script>

<template>
<div>
  <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Modal title</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          ...
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button type="button" class="btn btn-primary">Save changes</button>
        </div>
      </div>
    </div>
  </div>

  <header
      id="topnav"
      class="defaultscroll sticky bg-white"
  >
    <div class="container d-flex justify-content-center">
      <!-- Logo container-->
      <div>
        <router-link class="logo" to="/">
          <img :src="$store.state.style.theme === 'light' ? 'images/logo-dark.png' : 'images/logo-light.png'" height="24" alt="" />
        </router-link>
      </div>

      <!--end navigation-->
    </div>
    <!--end container-->
  </header>

    <!-- Start -->
    <section class="section">
      <form class="mt-0" @submit="formSubmit" onsubmit="return false;">
        <div class="container">
            <div class="row">
              <div class="col-lg-7 col-xl-8 shadow-lg mb-4">
                <div class="mb-2 px-4">
                  <Pricing :plans="plans" :is-included="true" :is-choosable="true"/>
                </div>

                <div>
                  <div class="p-4">
                    <p>Additional Extension(s) can be purchased after signup</p>
                    <h5 class="mb-0">Billing Details :</h5>


                    <div class="row mt-2">
                      <div class="col-12">
                        <div class="form-group position-relative">
                          <label>Company name <span class="text-danger">*</span></label>
                          <input name="companyName" id="companyName" type="text"
                                 class="form-control" placeholder="Company name :"
                                 v-model="form.companyName"
                                 required
                          >
                        </div>
                      </div>
                      <!--end col-->
                      <div class="col-12">
                        <div class="form-group position-relative">
                          <label>Full name <span class="text-danger">*</span></label>
                          <input name="name" id="fullname"
                                 v-model="form.fullName"
                                 type="text" class="form-control"
                                 placeholder="Full Name :"
                                 required
                          >
                        </div>
                      </div>
                      <!--end col-->
                      <div class="col-12">
                        <div class="form-group position-relative">
                          <label>Email <span class="text-danger">*</span></label>
                          <input name="email" id="email" type="email"
                                 class="form-control" placeholder="Email :"
                                 v-model="form.email"
                                 required
                          >
                        </div>
                      </div>
                      <!--end col-->

                    </div>
                    <!--end row-->
                  </div>
                </div>
              </div>
              <div class="col-lg-5 col-xl-4 shadow-lg mb-4 px-4">
                <div class="mt-4 mt-sm-0 pt-2 pt-sm-0">
                  <div class="py-4">
                    <div class="d-flex justify-content-center" v-if="!isInLoading.price">
                      <div class="spinner-border text-warning" role="status">
                        <span class="visually-hidden"></span>
                      </div>
                    </div>
                    <div class="table-responsive" v-else>
                      <table class="table table-center table-padding mb-0">
                        <tbody>
                        <tr>
                          <td class="h6 border-0">{{plan.name}}</td>
                          <td class="text-center font-weight-bold border-0">$ {{ plan.price }}</td>
                        </tr>
                        <tr>
                          <td colspan="2" class="border-0">
                            + {{plan.feature[2]}}
                          </td>
                        </tr>
                        <tr v-if="plan.priceForOthers">
                          <td colspan="2">
                                          <span>
                                            + ${{plan.priceForOthers}}/mo for each additional users
                                          </span>
                          </td>
                        </tr>
                        <tr v-if="!plan.fixQuantity">
                          <td class="h6 border-0">Quantity</td>
                          <td class="text-center font-weight-bold border-0 d-flex align-items-center justify-content-center">
                            <svg @click="plan.quantity--" height="28px" class="w-6 h-6 pointer" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                            <input type="number"
                                   class="form-control mx-2 text-center"
                                   min="1"
                                   :max="max_quantity"
                                   style="width: 50px"
                                   v-model="plan.quantity"
                            >
                            <svg @click="plan.quantity++" height="28px" class="w-6 h-6 pointer" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                          </td>
                        </tr>
                        <tr class="bg-light">
                          <td class="h5 font-weight-bold">Total</td>
                          <td class="text-center text-primary h4 font-weight-bold">$ {{ total }}</td>
                        </tr>
                        </tbody>
                      </table>
                      <div class="mt-4 pt-2 d-flex justify-content-center" v-if="recaptcha.apiKey">
                        <vue-recaptcha
                            ref="recaptcha"
                            @verify="saveCaptchaToken"
                            :sitekey="recaptcha.apiKey"
                            @expired="recaptcha.success = false"
                            @error="recaptchaError"
                        ></vue-recaptcha>
                      </div>
                      <div class="mt-4 pt-2">
                        <button type="submit" class="btn btn-block btn-primary"
                                :disabled="(!recaptcha.success && recaptcha.apiKey !== '') || isInLoading.submit"
                        >
                          <span v-if="!isInLoading.submit">Continue</span>
                          <template v-else>
                            <div class="d-flex align-items-center justify-content-center">
                              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                              <span class="ml-2"> Loading...</span>
                            </div>


                          </template>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--end row-->
        </div>
      </form>
        <!--end container-->
    </section>
    <!--end section-->
    <!-- End -->
    <!-- Back to top -->
    <a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" v-scroll-to="'#topnav'">
        <arrow-up-icon class="icons"></arrow-up-icon>
    </a>
    <!-- Back to top -->
</div>
</template>

<style scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}
.pointer {
  cursor: pointer;
  user-select: none;
}
</style>
